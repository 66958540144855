// @flow
import { createAction } from "redux-actions";

// Submit Public Evaluations
export const PUBLIC_EVALUATIONS_SUBMIT: string = "PUBLIC_EVALUATIONS_SUBMIT";
export const PUBLIC_EVALUATIONS_SUBMIT_REQUEST: string =
  "PUBLIC_EVALUATIONS_SUBMIT/REQUEST";
export const PUBLIC_EVALUATIONS_SUBMIT_SUCCESS: string =
  "PUBLIC_EVALUATIONS_SUBMIT/SUCCESS";
export const PUBLIC_EVALUATIONS_SUBMIT_FAILURE: string =
  "PUBLIC_EVALUATIONS_SUBMIT/FAILURE";
export const publicEvaluationsSubmit = createAction(PUBLIC_EVALUATIONS_SUBMIT);
export const publicEvaluationsSubmitRequest = createAction(
  PUBLIC_EVALUATIONS_SUBMIT_REQUEST
);
export const publicEvaluationsSubmitSuccess = createAction(
  PUBLIC_EVALUATIONS_SUBMIT_SUCCESS
);
export const publicEvaluationsSubmitFailure = createAction(
  PUBLIC_EVALUATIONS_SUBMIT_FAILURE
);
