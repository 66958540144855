// @flow
// API Public Evaluations static class
const apiBasePublicEvaluations = "/evaluations/public";
export default class PublicUrl {
  // Submit Answers for some public evaluation
  static PublicEvaluationsSubmitAnswers(
    api: Object,
    publicToken: string,
    data: Object
  ) {
    return api.POST(`${apiBasePublicEvaluations}/${publicToken}/answers`, data);
  }
}
