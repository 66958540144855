// @flow
// API Public UrL static class
const apiBasePublicUrl = "/p";
export default class PublicUrl {
  // Get Data for some token
  static GetPublicUrlDatas(
    api: Object,
    url: "evaluations" | "metric-pages" | "portfolios",
    tenant: string,
    dataId: string
  ) {
    if (url === "evaluations" && dataId) {
      return api.GET(`/${url}/public/${dataId}`);
    }
    return api.GET(`/${url}${apiBasePublicUrl}/${tenant}/${dataId}`);
  }
}
