// @flow
import moment from "moment";
// EVALUATIONS Utils:
/**
 * Decode Base64
 */
const decodeBase64 = (base64Url: string) => {
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(atob(base64));
};
export const decodeToken = (token) => decodeBase64(token.split(".")[1]);
export const decodeBody = (body: string) => decodeBase64(body);
/**
 * onSetMaxWidthForEachCheckbox
 */
export const onSetMaxWidthForEachCheckbox = () => {
  const titles = document.querySelectorAll<HTMLElement>(
    ".criteria .content .qualification-mark .title"
  );
  titles.forEach((title) => {
    const label = title.querySelector<HTMLLabelElement>(".ui.checkbox label");
    if (title && label) {
      const maxWidth = parseInt(
        (title.offsetWidth * 0.99 - 40 - 27).toString()
      );
      label.style.maxWidth = `${maxWidth}px`;
    }
  });
};
/**
 * setUTCtime
 */
export function setUTCtime(date?: string | Date | Moment) {
  return moment(date).utc();
}
/**
 * setUTCtime00
 */
export function setUTCtime00(date?: string | Date | Moment) {
  return (
    moment(date)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      // @ts-ignore
      .utc(moment.parseZone(date).utcOffset())
  );
}
/**
 * setParseUTCtimeToLocale
 */
export function setParseUTCtimeToLocale(date?: string | Date | Moment) {
  // @ts-ignore
  return moment(date).utc(moment.parseZone(date).utcOffset());
}
