// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PublicEvaluationsContainer from "./PublicEvaluations.container";
import ProtectedRoute from "../shared/ProtectedRoute";
const PublicEvaluationsRoute: React.ComponentType<any> = withRouter(
  connect(({ config }, ownProps) => {
    const { params } = ownProps.computedMatch;
    const { token } = params;
    return {
      component: PublicEvaluationsContainer,
      restProps: { url: "evaluations", token, config },
      isAllowedRoute: !!token,
      redirectTo: "/",
    };
  })(ProtectedRoute)
);

export default PublicEvaluationsRoute;
