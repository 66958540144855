// @flow
import { put, select, call, all, fork, takeLatest } from "redux-saga/effects";
import PublicEvaluationsApi from "../api/PublicEvaluations";
import { PublicEvaluations } from "../actions/actions";

// Submit Public Evaluations Answers
function* doPublicEvaluationsSubmitAnswers(action) {
  yield put(PublicEvaluations.publicEvaluationsSubmitRequest());
  window.logger.log("doPublicEvaluationsSubmitAnswers", action);
  const { body, token, callbackSuccess, callbackError } = action.payload;
  try {
    const api = yield select(({ api }) => api);
    // call the api to submit an answer for public evaluation
    const { data } = yield call(
      PublicEvaluationsApi.PublicEvaluationsSubmitAnswers,
      api,
      token,
      body
    );
    yield put(PublicEvaluations.publicEvaluationsSubmitSuccess());
    callbackSuccess(data);
  } catch (error) {
    window.logger.log("ErrorSaga - doPublicEvaluationsSubmitAnswers", error);
    const { data: errors } = error;
    const { message, details } = errors || "";
    yield put(PublicEvaluations.publicEvaluationsSubmitFailure());
    let errorMsg = message;
    if (details && details.length > 0) {
      errorMsg = `${errorMsg}: ${details[0].msg}`;
    }
    callbackError(errorMsg);
  }
}
export default function* publicEvaluationsSubmitSagas(): Generator<*, *, *> {
  yield all([
    fork(
      takeLatest,
      PublicEvaluations.PUBLIC_EVALUATIONS_SUBMIT,
      doPublicEvaluationsSubmitAnswers
    ),
  ]);
}
