// @flow
//Heto Sagas
import { all, fork } from "redux-saga/effects";
import { logger } from "../utils/logger/logger";
import companySagas from "./company";
import contactSagas from "./contact";
import authSagas from "./auth";
import usersSagas from "./users";
import publicUrlSagas from "./publicUrl";
import publicEvaluationsSagas from "./publicEvaluations";

function* readySaga() {
  // init logger:
  yield logger();
  yield window.logger.log("Sagas Ready!");
}

export function* sagas(): Generator<*, *, *> {
  yield all([
    fork(readySaga),
    fork(companySagas),
    fork(contactSagas),
    fork(authSagas),
    fork(usersSagas),
    fork(publicUrlSagas),
    fork(publicEvaluationsSagas),
  ]);
}
