// @flow
// Import Features
import * as React from "react";
// Import Components
import { Icon, Image, Message, Modal } from "semantic-ui-react";
import ErrorComponent from "../shared/ErrorComponent";
// Import images
import waitingForProcess from "../../images/waiting-for-process.svg";
// Types
type Props = {
  checkingPublicEvaluation: boolean,
  errorPublicEvaluation: string,
};
class PublicEvaluationView extends React.Component<Props> {
  render() {
    const {
      checkingPublicEvaluation,
      email,
      errorPublicEvaluation,
    } = this.props;
    return (
      <div id="main" className="activate-account">
        <Modal
          className="modal-create-company modal-activation-account seriously-fullscreen-mobile"
          dimmer={false}
          open={true}
          size={"small"}
        >
          <Modal.Header>
            <Modal.Header>
              {checkingPublicEvaluation && <h4>Loading ...</h4>}
            </Modal.Header>
          </Modal.Header>
          {checkingPublicEvaluation ? (
            <Modal.Content>
              <Image
                src={waitingForProcess}
                className="illustration mb50 mt50"
                alt="illustration-empty"
                centered
              />
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  We are loading the information{" "}
                  {email && `from email: ${email}`}.
                </Message.Content>
              </Message>
            </Modal.Content>
          ) : (
            <Modal.Content>
              {errorPublicEvaluation && (
                <ErrorComponent
                  className="activation-account-error"
                  errorMessageHeader={`Public Evaluation is not valid, check your url link please.`}
                  errorMessageContent={errorPublicEvaluation}
                />
              )}
            </Modal.Content>
          )}
        </Modal>
      </div>
    );
  }
}
export default PublicEvaluationView;
