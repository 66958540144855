// @flow
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import simpleNumberLocalizer from "react-widgets-simple-number";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import { configureStore, history } from "./store.js";
import registerServiceWorker, {
  unRegisterServiceWorker,
} from "./utils/serviceworker/registerServiceWorker";
// Styles Generic
import "./index.css";
import "./helpers.css"; //Generic helper classes
import "./bootstraphelpers.css"; //Bootstrap helper classes
import "./skejule-font.css"; //Heto Font
// Router
import HetoRouter from "./router.js";
//Config react-widgets:
moment.locale("en", {
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});
momentLocalizer();
simpleNumberLocalizer();
// Init Redux Store
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <HetoRouter />
    </ConnectedRouter>
  </Provider>,
  window.document.getElementById("root")
);
if (
  navigator.userAgent.toLowerCase().indexOf("safari") !== -1 &&
  navigator.userAgent.toLowerCase().indexOf("chrome") === -1
) {
  // NOT safari browser
  unRegisterServiceWorker();
} else {
  registerServiceWorker(store);
}
