// @flow
import baseConfig from "./base";

const testConfig = (): Object => {
  let config = {
    appEnv: "test",
    baseImg: "https://{tenant}.hetotest.com",
    api: {
      protocol: "",
      tenant: "",
      base: "/api",
      version: "v1",
    },
  };
  return Object.freeze(Object.assign({}, baseConfig, config));
};

export default testConfig();
