// @flow
// Import Features
import * as React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { decodeToken, decodeBody } from "./review/_EvaluationsReView.utils";
import { PublicUrl } from "../../actions/actions";
// Import Components
import PublicEvaluationView from "./PublicEvaluations.view";
// Types
type Props = {
  config: Object,
  token: string,
  getPublicEvaluationDatas: (payload: Object) => void,
  goTo: (payload: any) => void,
  url: "evaluations",
};
type State = {
  component: ?React.ElementType,
  evaluation: ?Object,
  errorPublicEvaluation: string,
  fetchingDatas: boolean,
  showPublicEvaluation: boolean,
  tenant: string,
  email: string,
  apiToken: string,
};
class PublicEvaluationContainer extends React.Component<Props, State> {
  // Privates properties:
  _isMounted = false;
  // Public properties:
  state = {
    component: null,
    evaluation: null,
    errorPublicEvaluation: "",
    fetchingDatas: true,
    showPublicEvaluation: false,
    tenant: "",
    email: "",
    apiToken: "",
  };
  // <Mounting Component>
  componentDidMount() {
    this._isMounted = true;
    this._onGetPublicEvaluationDatas();
  }
  // <Mounting Component>
  // <GET_PUBLIC_EVALUATION>
  _onGetPublicEvaluationDatas = () => {
    const onEndCallback = () => {
      this.setState({ fetchingDatas: false });
    };
    const { goTo } = this.props;
    this._onGettingPublicEvaluationDatas()
      .then(onEndCallback)
      .catch(() => {
        onEndCallback();
        setTimeout(() => {
          goTo("/");
        }, 10000);
      });
  };
  _onGettingPublicEvaluationDatas = () => {
    const { url, token, getPublicEvaluationDatas } = this.props;
    const { body: apiToken } = decodeToken(token);
    const { email, target, tenant } = decodeBody(apiToken);
    this.setState({ email, tenant, apiToken });
    return new Promise((resolve, reject) => {
      const formValues = {
        url,
        tenant,
        dataId: token,
      };
      const callbacks = {
        callbackError: (errorPublicEvaluation) => {
          window.logger.log(
            "PublicEvaluation - error - _onGettingPublicEvaluationDatas",
            token,
            errorPublicEvaluation
          );
          this.setState({ errorPublicEvaluation });
          reject();
        },
        callbackSuccess: (data: Object) => {
          window.logger.log(
            "PublicEvaluation - success - _onGettingPublicEvaluationDatas",
            data
          );
          const { evaluation: evaluationData } = data;
          let evaluation = { ...evaluationData };
          if (
            evaluation &&
            evaluation.isPublic &&
            evaluation.evaluatorsRole === "NoRegisteredUser" &&
            evaluation.status !== "closed"
          ) {
            const {
              answer,
              publicEvaluator,
              publicEvaluatorsAvailable,
            } = evaluation;
            const [publicEvaluatorAvailable] = publicEvaluatorsAvailable;
            evaluation = {
              ...evaluation,
              canSubmit: !answer,
              evaluator: publicEvaluator || publicEvaluatorAvailable,
              target,
            };
            this.setState({ evaluation });
            this._loadingComponent(resolve);
          } else {
            this.setState({
              errorPublicEvaluation:
                "Evaluation has been closed. If the problem persist, check with your school administrator.",
            });
            reject();
          }
        },
      };
      const payload = Object.assign({}, formValues, callbacks);
      getPublicEvaluationDatas(payload);
    });
  };
  // </GET_PUBLIC_EVALUATION>
  // <METHODS_PRIVATES>
  _loadingComponent = (resolve: () => void) => {
    const { url } = this.props;
    let componentType = url
      .split("-")
      .map(
        (partialUrlName) =>
          partialUrlName.charAt(0).toUpperCase() + partialUrlName.slice(1)
      )
      .join("");
    window.logger.log("_loadingComponent", url, componentType);
    (async () => {
      const component = await import(
        `./review/${componentType}ReView.container`
      );
      this.setState({
        component: component.default,
      });
      setTimeout(() => {
        this.setState({ showPublicEvaluation: true });
        resolve();
      }, 500);
    })();
  };
  // </METHODS_PRIVATES>
  // <RENDERS>
  render() {
    const {
      fetchingDatas,
      errorPublicEvaluation,
      component: EvaluationsReViewContainer,
      showPublicEvaluation,
      email,
      ...remainState
    } = this.state;
    if (!showPublicEvaluation) {
      return (
        <PublicEvaluationView
          checkingPublicEvaluation={fetchingDatas}
          email={email}
          errorPublicEvaluation={
            (errorPublicEvaluation || "Error Public Evaluation") +
            ` from email: ${email}`
          }
        />
      );
    }

    return (
      EvaluationsReViewContainer && (
        <EvaluationsReViewContainer {...remainState} token={this.props.token} />
      )
    );
  }
  // </RENDERS>
  // <Updating Component>
  // </Updating Component>
  componentWillUnmount() {
    this._isMounted = false;
  }
}
export default connect(null, (dispatch) => ({
  getPublicEvaluationDatas: (payload) => dispatch(PublicUrl.publicUrl(payload)),
  goTo: (path) => dispatch(push(path)),
}))(PublicEvaluationContainer);
